ul.list-autocomplete {
  list-style: none;
  width: 97%;
  padding: 0;
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 4px;
  right: 0;
}

.list-autocomplete li {
  padding: 0;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  background-color: #ffffff;
  font-size: 1rem;
}

.list-autocomplete li:last-child {
  border-bottom: 0;
}

.list-autocomplete li:hover {
  background: #e8e8e8;
}
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}
/*when hovering an item:*/
.autocomplete-items li:hover {
  background-color: #e9e9e9;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

/* segundo autocomplate que usa datalist */
/*
.wrapper {
  width: 100%;
  height: 100%;
  background: #efefef;
  box-shadow: 1px 1px 10px #999;
  margin: auto;
  text-align: center;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding-top: 5px;
}*/

.scroll {
  overflow-x: scroll;
  overflow-y: scroll;
  height: 550px;
  white-space: nowrap;
}

.list-autocomplete li:focus {
  background-color: red;
}

.input {
  padding: 5px 10px;
  font-size: 16px;
  color: #555;
  border: 1px solid #b1b1b1;
  border-radius: 5px;
  outline: none;
  position: relative;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.input:focus {
  border: 1px solid #777;
}

.list {
  z-index: 99;
  position: absolute;
  width: 100%;
  top: 74px;
  max-height: 250px;
  overflow-x: scroll;
  background: rgba(33, 33, 33, 0.7);
  color: #fff;
  border-radius: 5px;
  overflow-x: hidden;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.list li {
  padding: 6px 10px;
  cursor: pointer;
  font-size: 15px;
}

.list li:hover {
  background: rgba(33, 33, 33, 0.3);
}

.arrow-up {
  z-index: 99;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(33, 33, 33, 0.7);
  position: absolute;
  top: 64px;
  left: 20px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.show {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.hide {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: none;
  transition: none;
}

.selecting {
  background: rgba(33, 33, 33, 0.3);
}

body,
p,
h1,
ul,
li,
blockquote {
  padding: 0px;
  margin: 0px;
}

body {
  font-family: Georgia, serif;
  color: #848688;

  word-wrap: break-word !important;
  font: 87.5%/1.5em Open Sans, sans-serif;
}

li {
  list-style: none;
}
.dropdown-menu .nav-link:hover {
  /*background-image: linear-gradient(to right, #2196f3, #0095ff, #0971f1);*/
  background-image: -webkit-gradient(linear, left top, right top, from(#a7c4d2), color-stop(#a7c4d2), to(#a7c4d2));
  background-image: linear-gradient(to right, #a7c4d2, #a7c4d2, #a7c4d2);
  color: #fff !important;
  font-weight: bold;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
  border-radius: 5px;
}

.estrutura {
  display: grid;
  grid-template-columns: minmax(160px, 1fr) 3fr minmax(300px, 1fr);
  grid-template-areas:
    'header header header'
    'content content anuncios'
    'footer footer footer';
}

.search {
  width: 100%;
  position: relative;
  display: inline-table;
}

.searchTerm {
  width: 70%;
  border: 3px solid #fff;
  border-right: none;
  padding: 5px 5px 2px 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
  margin-left: 10px;
}

.searchTerm:focus {
  color: #d21212;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #d21212;
  background: #d21212;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#btn-pesquisar {
  height: 31px;
  width: 36px;
}

#search {
  width: 250px;
  height: 25px;
  border-radius: 5px;
}
@media (max-width: 760px) {
  .fc .fc-toolbar-title {
    font-size: 1em !important;
  }
  .estrutura {
    grid-template-columns: 100%;
    grid-template-areas:
      'header'
      'content'
      'sidenav'
      'anuncios '
      'footer ';
  }
  #search {
    width: 80%;
  }
}

.header {
  grid-area: header;
  background: linear-gradient(135deg, #1380d6, #2196f3);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
@media (max-width: 499px) {
  #logo {
    display: block;
  }
  .search {
    width: 100%;
  }
}
@media (max-width: 1034px) {
  .searchTerm {
    margin-left: 0px;
  }
  .header {
    grid-template-columns: auto;
  }
  nav {
    margin: 0;
  }

  #logo {
    display: flex;
    padding: 0;
    width: 100%;
    text-align: center;
    float: none;
  }
}
@media (max-width: 760px) {
  .header {
    grid-template-columns: auto;
  }
}

/*FOOTER*/

.footer {
  margin-top: 30px;
}
.footer p {
  color: white;
  text-align: center;
  padding: 20px;
}

/* CSS TCDOC PERSONALIZADO*/

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1210px;
  }
}
textarea {
  box-sizing: border-box;

  max-width: 100%;
}
@media (max-width: 800px) {
  .modal-dialog {
    max-width: 730px;
    margin: 1.75rem auto;
  }
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(255, 255, 255, 0);
}

th {
  font-weight: 100;
}
thead th {
  font-weight: bold;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #606468;
}
/*footer   {
    background-image: linear-gradient(to right, #2196f3 ,#0095ff, #0971f1);
  }*/
.navbar,
footer {
  background: #000;
}
.card {
  margin-top: 25px;
  border-radius: 25px !important;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
thead tr th:first-child {
  border-radius: 25px 0px 0px 0px;
}
thead tr th:last-child {
  border-radius: 0px 25px 0px 0px;
}
.card-header {
  border-radius: 25px 25px 0px 0px !important;

  padding: 13px;
  margin-bottom: 0;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
  color: #063441;
  text-align: left;
  font-weight: bold;
  background: #f8f9fb;
  /* border-top: 8px solid #f8f9fb;*/
}
.card-red .card-header {
  color: #fff;
}

.card-header > span {
  font-weight: bold;
}

.card .span-card-titulo {
  font-size: 16px;
  padding: 6px 25px 6px 6px;
  top: 1px;
  position: absolute;
  background-color: #f8f9fb;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #063441;
  font-weight: 550 !important;
}
.card .span-card-titulo:after {
  content: '';

  position: absolute;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #f8f9fb;
}
.card-red .card-header {
  background: #eaa2a8;
  border-top: none;
  /* padding: 17px;*/
}
.card-success #btn_ibge {
  background: #a7c4d2;
}

.tela-pendente .card-success .card-header {
  background: #a7c4d2;
  border-top: none;
  padding: 17px;
  color: white;
}
.tela-pendente .card .span-card-titulo {
  font-size: 16px;
  padding: 6px 25px 6px 6px;
  top: 1px;
  position: absolute;
  background-color: #f8f9fb;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  color: #fff;
  font-weight: 550 !important;
}
.tela-pendente .card-success .span-card-titulo {
  background: #a7c4d2;
  font-size: 16px;
  padding: 6px 25px 6px 6px;
  font-weight: bold;
  border-top: 0px solid #a7c4d2;
}
.card-success .badge-primary {
  background: #a7c4d2;
}
.card-success .card-header {
  background: #a7c4d2;
  border-top: none;
  padding: 17px;
  color: white;
}
.card-red .modal-footer {
  justify-content: left;
}
.modal-content {
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
}

.modal-dialog {
  border: 15px !important;
}

.modal .card-red form button {
  width: 100px;
  height: 35px;
  font-size: 14px;
}

.modal .card-red .modal-footer button {
  width: 100px;
  height: 35px;
  font-size: 14px;
}

.modal .card form button {
  width: 100px;
  height: 35px;
  font-size: 14px;
}

.modal .card-success form button {
  width: 100px;
  height: 35px;
  font-size: 14px;
}

#btn_ibge {
  width: 42px;
  height: 38px;
}
.card-success .modal-footer {
  justify-content: left;
}
.card-success .modal-footer button {
  width: 100px;
  height: 35px;
}
.card-red .span-card-titulo:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: none;
}
.card-success .span-card-titulo:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: none;
}
.card-red .span-card-titulo {
  background: #eaa2a8;
  font-size: 16px;
  padding: 6px 25px 6px 6px;
  color: white;
  font-weight: bold;
  border-top: 0px solid #ce8787;
}
.card-success .span-card-titulo {
  background: #a7c4d2;
  font-size: 16px;
  padding: 6px 25px 6px 6px;

  font-weight: bold;
  border-top: 0px solid #a7c4d2;
}

.card .span-card-titulo .img-card {
  width: 28px;
  margin-right: 5px;
}
.card table thead {
  background-color: #a7c4d2 !important;
}
.card table button {
  width: 33px;
  height: 31px;
}

.fc-col-header tr {
  background-color: #eceff0 !important;
  /* background-color: aliceblue !important;*/
  /*color: #fff;*/
}

.card-body .demo-app button {
  background-color: #e3e2f0 !important;
  color: #3c4858 !important;
}
.btn-toggleweekends {
  background-color: #a7c4d2 !important;
  color: #fffefc !important;

  border-radius: 25px;
}
.fc .fc-button {
  border: 0px solid #000;
}
.fc .fc-button-group > .fc-button {
  margin: 0px 5px;
}
.card-body .demo-app .fc-button-active {
  background-color: #a7c4d2 !important;
  color: #fffefc !important;
}

.card-body .demo-app-sidebar .css-yk16xz-control {
  border-radius: 25px !important;
}
.card-body {
  background: #f8f9fb;
  border-radius: 0px 0px 25px 25px !important;
}
.card-body-lista {
  padding: 0.15rem;
}

.nav-pills .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-pills .nav-link,
.nav-pills > .nav-link {
  color: #333;
  background-color: #eee;
}
.nav-pills-modal .nav-link,
.nav-pills-modal > .nav-link {
  color: #333;
  background-color: #6c747d;
}
.bg-gray {
  background-color: #eee;
}

.border-gray {
  border-color: #eee;
}

.table td {
  padding: 0.3rem;
  vertical-align: center;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.table-hover tbody tr:hover {
  background-color: #545b627a;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #a7c4d2;
}
#login .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #7d9aa2;
}
#login .nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3b4858;
}

.legenda-consultas {
  display: flex;
  justify-content: space-between;
  word-break: break-all;
  margin: 10px;
  flex-flow: wrap;
}
#cons_medico_consulta {
  z-index: 99;
}

#regForm .prevBtn {
  width: 108px;
  height: 35px;
  border-radius: 15px;
  background: #b5c4cc;
  border-color: #b5c4cc;
  color: white;
  border: 0px;
}
#regForm .prevBtn:hover {
  background: #869298;
  border-color: #869298;
}
.btn-secondary {
  width: 108px;
  height: 35px;
  background: #b5c4cc !important;
  border-color: #b5c4cc !important;
}
.btn-secondary:hover {
  background: #a4b2b9 !important;
  border-color: #a4b2b9 !important;
}

li > a {
  font-weight: bold;
}
button {
  font-weight: bold;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #1fa193;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.demo-app {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-top {
  margin: 0 0 3em;
}

.demo-app-calendar {
  margin: 0 auto;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

/* ---------- LOGIN ---------- */

/* login */

div.main {
  background: #003641; /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 12+ */ /* IE10+ */
  background: radial-gradient(ellipse at center, #2196f3 1%, #4585b9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003641', endColorstr='#1c2b5a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  height: calc(100vh);
  width: 100%;
}

[class*='fontawesome-']:before {
  font-family: 'FontAwesome', sans-serif;
}

/* ---------- GENERAL ---------- */

* {
  box-sizing: border-box;
  margin: 0px;
}
*:before,
*:after {
  box-sizing: border-box;
}

h5 {
  color: #eee;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input {
  border: none;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  padding: 0;
  -webkit-appearance: none;
}

p {
  line-height: 1.5em;
}

.clearfix_login {
  *zoom: 1;
}
.clearfix_login:before .clearfix_login:after {
  content: ' ';
  display: table;
}
.clearfix_login:after {
  clear: both;
}

.container_login {
  left: 50%;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.container_login .card-body {
  padding: 0px;
}
.content {
  min-height: 100%;
}

#login form {
  /* width: 250px;*/
  width: 95%;
}
#login,
.logo {
  display: inline-block;
  width: 40%;
}
#login {
  /*border-right:1px solid #fff;*/
  padding: 0px 22px;
  width: 100%;
}
.logo {
  color: #fff;
  font-size: 50px;
  line-height: 125px;
}
/*
#login form p span {
  background-color: #2196f3;
  border-radius: 3px 0px 0px 3px;
  color: #fff;
  display: block;
  float: left;
  height: 50px;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}*/
/*
#login form input {
  height: 50px;
}*/
fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}
/*
#login form input[type='text'],
input[type='password'] {
  background-color: #fff;
  border-radius: 0px 3px 3px 0px;
  color: #000;
  margin-bottom: 1em;
  padding: 0 16px;
  width: 200px;
}*/

#login form input[type='submit'],
.esqueci-senha {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #7d9aa2;
  color: #fff;
  font-weight: bold;
  /* margin-bottom: 2em; */
  text-transform: uppercase;
  padding: 5px 10px;
  height: 30px;
}

#login form input[type='submit']:hover,
.esqueci-senha:hover {
  background-color: #80abb7;
  color: #fff;
}

#login > p {
  text-align: center;
}

#login > p span {
  padding-left: 5px;
}
.middle {
  display: flex;
  width: 600px;
}

/* fim do login */

/* BUTTONS */

label {
  font-weight: bold !important;
}

button {
  font-weight: bold !important;
}
.btn-excluir {
  border-left: 1px solid rgb(114, 115, 118);
}
.bg-51comvc {
  background-color: #2196f3;
  color: white;
  border-radius: 5px;
}
.color-51comvc {
  color: #2196f3;
}
.color-tema {
  color: #2196f3;
}
.bg-red {
  background-color: #c98888;
  color: white;
}
#btn_visualizar {
  background-color: #2196f3;
  width: 40px;
  color: white;
}
.bg-black {
  background-color: #2196f3;
  color: white;
}
.btn-51comvc {
  background-color: #2196f3;

  color: white;
  width: 108px;
  /* width: 102px;*/
  height: 35px;
}

/*FIM DOS BUTTONS*/

/* Checkbox ON / OFF */

.material-switch > input[type='checkbox'] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
  /*width: 40px;*/
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  width: 50px;
  /*width: 70px;*/
}
.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';

  left: 0px;
  margin-top: -8px;
  position: absolute;
  top: -2px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 20px;
  height: 20px;
  /* top: -4px;
  left: -4px;*/
  /* width: 24px;*/
  /*height: 24px;*/
}
.material-switch > input[type='checkbox']:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.material-switch > input[type='checkbox']:checked + label::after {
  background: inherit;
  /* left: 50px;*/
  left: 34px;
}

/* Checkbox */

.textarea-responsive {
  box-sizing: border-box;

  width: 100% !important;
}
.wrapper-tooltip {
  text-transform: uppercase;
  /*  background: #ececec;*/
  color: #555;
  cursor: help;
  font-family: 'Gill Sans', Impact, sans-serif;
  font-size: 20px;
  margin-top: 3px;
  /*margin: 100px 75px 10px 75px;*/
  /* padding: 15px 15px;*/
  position: relative;
  text-align: center;
  /* width: 40px;
  height: 40px;*/
  /* -webkit-transform: translateZ(0); */ /* webkit flicker fix */
  /* -webkit-font-smoothing: antialiased;*/ /* webkit text rendering fix */
}
.input-group-text {
  font-size: 21px !important;
}
.wrapper-tooltip .tooltip {
  background: #343a40;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -25px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;

  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper-tooltip .tooltip:before {
  bottom: -20px;
  content: ' ';
  display: block;
  /* height: 20px;*/
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper-tooltip .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #343a40 10px;
  bottom: -10px;
  content: ' ';
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper-tooltip:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper-tooltip .tooltip {
  display: none;
}

.lte8 .wrapper-tooltip:hover .tooltip {
  display: block;
}

#regForm {
  font-family: Raleway;
  /* background-color: #ffffff;
  margin: 100px auto;

  padding: 40px;
  width: 70%;
  min-width: 300px;*/
}

#regForm h1 {
  text-align: center;
}

#regForm input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
}

/* Mark input boxes that gets an error on validation: */
#regForm input.invalid {
  background-color: #ffdddd;
}

/* Hide all steps by default: */
#regForm .tab {
  display: none;
}

/*#regForm .prevBtn {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 0px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
  border-radius: 15px;
}*/

.css-1fhf3k1-control {
  background-color: hsl(210deg 16% 93%) !important;
  border-color: #aaa !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  color: #999999;
}
#regForm button:hover {
  opacity: 0.8;
}
/*
.prevBtn {
  background-color: #bbbbbb;
}*/
.tab-form-btn {
  text-align: left;
}
@media (min-width: 760px) {
  .tab form {
    min-height: 520px;
  }
  .tab-form {
    min-height: 520px;
  }
  .tab-form-btn {
    text-align: left;
   /* position: absolute;
    right: 0px;
    bottom: 0px;*/
  }
}
/* Make circles that indicate the steps of the form: */
/*#regForm .step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

#regForm .step.active {
  opacity: 1;
}
*/
/* Mark the steps that are finished and valid: */
/*#regForm .step.finish {
  background-color: #4caf50;
}
*/
/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}
#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 20%;
  float: left;
  position: relative;
}
#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #fff;
  background: #bfbfbf;
  border-radius: 13px;
  margin: 0 auto 5px auto;
  text-align: center;
}
/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #bfbfbf;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}
#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #ffc107;
  color: white;
}

#progressbar li.finish:before,
#progressbar li.finish:after {
  background: #27ae60;
  color: white;
}
#navbarSupportedContent {
  z-index: 999;
  min-height: 60px;
}
/*#navbarSupportedContent:after {
  content: '';

  position: absolute;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #ce8787;
}
*/
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #dadcdb;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dadcdb;
}
nav ul li {
  padding-left: 15px;
}
.ml-auto li {
  margin-left: 54px;
}
.Toastify__toast-container {
  z-index: 99999;
}
.btn-acao {
  width: 35px;
  height: 35px;
  display: inline;
}
.modal-content {
  background-color: rgb(0 0 0 / 0%);
  border: 0px solid rgba(0, 0, 0, 0.2);
}
.modal-content button {
  border-radius: 10px;
}

.btn_Cadastrar {
  background: #a7c4d2;
  border-radius: 10px;
  color: white;
  height: 40px;
}
.btn_conculuir_conulta {
  background: #4caf50ad !important;
  border-radius: 10px !important;
  color: white !important;
  height: 35px !important;
  width: 183px !important;
  font-size: 14px;
}
.btn_andamento_conulta{
 
background-color: #e1b42d;
  border-radius: 10px !important;
  color: white !important;
  height: 35px !important;
  width: 227px !important;
  font-size: 14px;
}
#regForm .nextBtn {
  background: #a7c4d2;
  border-radius: 10px;
  padding: 0px;
  color: white;
  height: 40px;
}
#regForm .nextBtn:hover {
  background: #84bbd6;
  color: white;
}
.btn_Cadastrar:hover {
  background: #84bbd6;
  color: white;
}
.btn_andamento_conulta:hover{
  background: #fec107 !important;
  color: white !important;
  font-size: 14px;
}
.btn_conculuir_conulta:hover{
  background: #4caf50f2 !important;
  color: white !important;
  font-size: 14px;
}
.fild-required{
color: red;
}
.btn_excluir {
  background: #eaa2a8;
  border-radius: 10px;
  color: white;
  height: 40px;
}
.btn_excluir:hover {
  background: #e67e87;
  color: white;
}
input:not(input[type='radio']) {
  border: 2px solid #a7c4d2;
  border-radius: 15px !important;
}
input:disabled {
  border: 0px solid #a7c4d2 !important;
}
.css-yk16xz-control {
  border-radius: 15px !important;
  border: 2px solid #a7c4d2 !important;
}


.css-yk16xz-control input {
  border: 0px solid #000 !important;
}

.badge-primary {
  background-color: #a7c4d2 !important;
}

/* fullcalendar*/

.demo-app-main tbody tr th:last-child,
td:last-child {
  /* border: 1px solid #ddd;*/
  border: 1px solid #f8f9fb !important;
}
.demo-app-main tbody tr td:last-child {
  border: 1px solid #c7e0ec !important;
}

.fc-col-header tr {
  border-radius: 25px;
}
.card .demo-app-main table thead {
  background-color: #f8f9fb !important;
}
.fc-theme-standard .fc-scrollgrid {
  border-radius: 35px;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='radio'] {
    --active: #275efe;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: #275efe;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--border);
    border: 1px solid var(--bc, var(--border));
    background: var(--background);
    background: var(--b, var(--background));
    -webkit-transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type='radio']:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    -webkit-transition: opacity 0.2s,
      -webkit-transform 0.3s ease;
    transition: opacity 0.2s,
      -webkit-transform 0.3s ease;
    transition: transform 0.3s ease,
      opacity 0.2s;
    transition: transform 0.3s ease,
      opacity 0.2s,
      -webkit-transform 0.3s ease;
    -webkit-transition: opacity var(--d-o, 0.2s),
      -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
    transition: opacity var(--d-o, 0.2s),
      -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s),
      -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
  }
  input[type='radio']:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  input[type='radio']:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type='radio']:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type='radio']:disabled + label {
    cursor: not-allowed;
  }
  input[type='radio']:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type='radio']:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type='radio']:not(.switch) {
    width: 21px;
  }
  input[type='radio']:not(.switch):after {
    opacity: 0;
    opacity: var(--o, 0);
  }
  input[type='radio']:not(.switch):checked {
    --o: 1;
  }
  input[type='radio'] + label {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }

  input[type='radio'] {
    border-radius: 50%;
  }
  input[type='radio']:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-transform: scale(var(--s, 0.7));
            transform: scale(var(--s, 0.7));
  }
  input[type='radio']:checked {
    --s: 0.5;
  }
}
input[type='radio'] + label {
  display: inline;
  display: initial;
}

input[type='radio']:checked {
  --b: #78bbdb;
  --bc: #a7c4d2;
  --d-o: 0.3s;
  --d-t: 0.6s;
  --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.horario-prontuario {
  width: 135px;
  height: 38px;
  color: #75b8d9;
  border: 2px solid #a7c4d2;
  display: inline-block;
  border-radius: 12px;
  padding: 4px;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  background-color: white;
}
.listar-senhas   {
  border-collapse: collapse;
  width: 100%; /* Define a largura como 100% para ocupar a tela inteira */
  margin: 20px 0; /* Adiciona margens superior e inferior para espaçamento */

}

.listar-senhas th, .listar-senhas td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.listar-senhas th {
  background-color: #f2f2f2;
}


.listar-senhas > .selected {
  background-color: #aaf;
}
.last-ticket {
  width: 78%;
  background-color: #f0f0f0;
  padding: 10px;
  text-align: center;
}
/*
.last-ticket h2 {
  font-size: 24px;
}
*/
.ticket-list {
  width: 22%;
  background-color: #e0e0e0;
  padding: 20px;
  font-size: 82px;
  line-height: 0.8;
}
/*
.ticket-list h2 {
  font-size: 18px;
}*/

#ticket-list {
  list-style-type: none;
  padding: 0;
}

#ticket-list li {
 /* font-size: 20px;*/
  margin-bottom: 10px;
}

.last-ticket-center{
font-size: 200px;
}

/* Grid */
.grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 50px;
}

/* 1 */

.grid-area-1 .item-1 {
	grid-area: 1 / 2 / 4 / 3;
	grid-row-start: 1;
	grid-column-start: 2;
	grid-row-end: 4;
	grid-column-end: 3;
}

.grid-area-1 .item-5 {
	grid-area: span 2 / 3;
	grid-row-start: span 2;
	grid-column-start: 3;
}





/* Grid Item */
.item {
	margin: 5px;
	background: tomato;
	text-align: center;
	font-size: 1.5em;
}

.grid {
	max-width: 400px;
	margin: 0 auto;
	border: 1px solid #ccc;
}


